/* ngx-slider */
.ngx-slider {
  margin-top: 34.5px!important;
}
.ngx-slider .ngx-slider-bar {
  background: #0277bd!important;
  height: 2px!important;
}
.ngx-slider .ngx-slider-pointer {
  background: #ff5722!important;
}
.ngx-slider .ngx-slider-pointer.ngx-slider-active:after {
  background-color: #0277bd!important;
}
.ngx-slider .ngx-slider-bubble {
  font-size: 13px!important;
}

/* ag-grid: Oni row koji imaju dodatu klasu disabled_row_class imaju simulaciju selektvanosti i disejblovanosti */
.ag-theme-alpine .disabled_row_class {
  background-color: lightgrey!important;
}
.ag-theme-alpine .disabled_row_class .ag-checkbox-input-wrapper::after {
  color: gray!important;
  content: "\f106"!important;
}
.ag-theme-alpine .ag-header {
  font-size: 13px !important;
}
.ag-theme-alpine .ag-row {
  font-size: 13px !important;
}
.ag-theme-alpine .ag-footer {
  font-size: 13px !important;
}


/* mat-tree - ikonice se pale na hover*/
.mat_tree_node:hover .incon_on_view_asset{
  display: block!important;
}

/* minimalna visina ag-grid */
table-view .ag-center-cols-clipper {
  min-height: 150px !important;
}

.no_margin_strong{
  margin: 0px!important;
}

/* Stampa prepiske */
@media print {
  .ql-toolbar.ql-snow + .ql-container.ql-snow {
    border: 0px!important;
  }
}

/* Za bug kada se ne latinici u velikim slovima ošiša kvačica na inputima*/
.mat-form-field-infix label {
  padding-top: 2px!important;
}

/* Za field bez margina */
.mat-form-field.no-padding .mat-form-field-wrapper {
  margin: 0!important;
}
/* redni broj na karticama */
.ordinal-card-number{
  position: absolute;
  right: 4px;
  top:4px; 
  font-size: 11px
}
/* visual warning na karticama */
.visual-warning-card{
  position: absolute;
  right: 24px;
  top:2px;
  z-index: 999
}
/* timer na karticama */
.timer-card{
  position: absolute;
  right: 48px;
  top:2px;
  z-index: 999
}
/* Full-calendar */
.hidden-calendar-event-class{
  display: none!important;
}
/* -AUTOCOMPLETE validacije - bojenje linije na inputu */
/*red-underline-class i green-underline-class se dodaju zavisno od vrste inputa: */
basic-autocomplete .red-underline-class .mat-form-field-underline,
lookups-autocomplete .red-underline-class .mat-form-field-underline,
users-autocomplete .red-underline-class .mat-form-field-underline,
contracts-autocomplete .red-underline-class .mat-form-field-underline,
customers-autocomplete .red-underline-class .mat-form-field-underline,
currency-autocomplete .red-underline-class .mat-form-field-underline,
items-autocomplete .red-underline-class .mat-form-field-underline,
organizationUnit-autocomplete .red-underline-class .mat-form-field-underline,
asset-models-autocomplete .red-underline-class .mat-form-field-underline,
asset-categories-autocomplete .red-underline-class .mat-form-field-underline,
asset-manufacturers-autocomplete .red-underline-class .mat-form-field-underline,
asset-types-autocomplete .red-underline-class .mat-form-field-underline {
  background-color: #e53935!important;
  height: 2px;
}
basic-autocomplete .red-underline-class .mat-form-field-underline .mat-form-field-ripple,
lookups-autocomplete .red-underline-class .mat-form-field-underline .mat-form-field-ripple,
users-autocomplete .red-underline-class .mat-form-field-underline .mat-form-field-ripple,
contracts-autocomplete .red-underline-class .mat-form-field-underline .mat-form-field-ripple,
customers-autocomplete .red-underline-class .mat-form-field-underline .mat-form-field-ripple,
currency-autocomplete .red-underline-class .mat-form-field-underline .mat-form-field-ripple,
items-autocomplete .red-underline-class .mat-form-field-underline .mat-form-field-ripple,
organizationUnit-autocomplete .red-underline-class .mat-form-field-underline .mat-form-field-ripple,
asset-models-autocomplete .red-underline-class .mat-form-field-underline .mat-form-field-ripple,
asset-categories-autocomplete .red-underline-class .mat-form-field-underline .mat-form-field-ripple,
asset-manufacturers-autocomplete .red-underline-class .mat-form-field-underline .mat-form-field-ripple,
asset-types-autocomplete .red-underline-class .mat-form-field-underline .mat-form-field-ripple {
  background-color: #e53935!important;
}
basic-autocomplete .green-underline-class .mat-form-field-underline,
lookups-autocomplete .green-underline-class .mat-form-field-underline,
users-autocomplete .green-underline-class .mat-form-field-underline,
contracts-autocomplete .green-underline-class .mat-form-field-underline,
customers-autocomplete .green-underline-class .mat-form-field-underline,
currency-autocomplete .green-underline-class .mat-form-field-underline,
items-autocomplete .green-underline-class .mat-form-field-underline,
organizationUnit-autocomplete .green-underline-class .mat-form-field-underline,
asset-models-autocomplete .green-underline-class .mat-form-field-underline,
asset-categories-autocomplete .green-underline-class .mat-form-field-underline,
asset-manufacturers-autocomplete .green-underline-class .mat-form-field-underline,
asset-types-autocomplete .green-underline-class .mat-form-field-underline {
  /* background-color: green!important; */
  background-color: #0277bd!important;
  height: 1px;
}
/* kada je fokusiran, vracamo na staro (plavo) kakva god da je klasa */
basic-autocomplete .mat-focused .mat-form-field-underline .mat-form-field-ripple,
lookups-autocomplete .mat-focused .mat-form-field-underline .mat-form-field-ripple,
users-autocomplete .mat-focused .mat-form-field-underline .mat-form-field-ripple,
contracts-autocomplete  .mat-focused .mat-form-field-underline .mat-form-field-ripple,
customers-autocomplete .mat-focused .mat-form-field-underline .mat-form-field-ripple,
currency-autocomplete .mat-focused .mat-form-field-underline .mat-form-field-ripple,
items-autocomplete .mat-focused .mat-form-field-underline .mat-form-field-ripple,
organizationUnit-autocomplete .mat-focused .mat-form-field-underline .mat-form-field-ripple,
asset-models-autocomplete .mat-focused .mat-form-field-underline .mat-form-field-ripple,
asset-categories-autocomplete .mat-focused .mat-form-field-underline .mat-form-field-ripple,
asset-manufacturers-autocomplete .mat-focused .mat-form-field-underline .mat-form-field-ripple,
asset-types-autocomplete .mat-focused .mat-form-field-underline .mat-form-field-ripple { 
  background-color: #0277bd!important;
}
basic-autocomplete .mat-focused mat-hint,
lookups-autocomplete .mat-focused mat-hint,
users-autocomplete .mat-focused mat-hint,
contracts-autocomplete .mat-focused mat-hint,
customers-autocomplete .mat-focused mat-hint,
currency-autocomplete .mat-focused mat-hint,
items-autocomplete .mat-focused mat-hint,
organizationUnit-autocomplete .mat-focused mat-hint,
asset-models-autocomplete .mat-focused mat-hint,
asset-categories-autocomplete .mat-focused mat-hint,
asset-manufacturers-autocomplete .mat-focused mat-hint,
asset-types-autocomplete .mat-focused mat-hint {
  color: #0277bd!important;
}

/* MATERIJALNI TROSKOVI, izmena na selektovanim redovima */
datatable-body-row.datatable-body-row.active .datatable-row-center{
  background-color: #ffcccc!important;
}
datatable-body-row.datatable-body-row.active .datatable-row-center:hover{
  background-color: #ffb3b3!important;
}
/* prilikom stampe radio button-a na ovaj nacin se prikazuje izabrana vrednost */
@media print {
  .mat-radio-inner-circle {
    box-shadow: 0 0 0 1000px #00695C inset !important;
  }
}
/* prilikom stampe checkbox-a na ovaj nacin se prikazuje stiklirana vrednost */
@media print {
  .mat-checkbox-checked.mat-accent .mat-checkbox-background {
     background-color: #000000  !important; 
     -webkit-print-color-adjust: exact;
  } 
}
/* UP DOWN ikonica morala je iz dva dela, sa :before i :after */
 .sort-btn:after{
  content: ' ';
  color: rgba(0, 0, 0, 0.54);
  box-sizing: border-box;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid;
  border-bottom: 0px solid;
  margin: 0 4px;
  top: 24px;
  position: absolute;
}
.sort-btn:before{
  content: ' ';
  color: rgba(0, 0, 0, 0.54);
  box-sizing: border-box;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid;
  border-top: 0px solid;
  margin: 0 4px;
  top: 19px;
  position: absolute;
}
/* Prve dve ikonice za sortiranje ne treba da postone zbog kolone numerisanja i kolone checkbox-a */
datatable-header-cell:nth-child(1) .sort-btn:before,
datatable-header-cell:nth-child(1) .sort-btn:after,
datatable-header-cell:nth-child(2) .sort-btn:before,
datatable-header-cell:nth-child(2) .sort-btn:after,
datatable-header-cell:nth-child(3) .sort-btn:before,
datatable-header-cell:nth-child(3) .sort-btn:after{
  border-left: 0px solid;
  border-right: 0px solid;
  border-top: 0px solid;
  border-bottom: 0px solid;
}
/* Kada je UP ili DOWN onda i :after i : before moraju da se se gadjaju da bi se pregazilo difoltno gore postavljeno "UPDOWN ikonica" */
.sort-desc.datatable-icon-down:after, .sort-desc.datatable-icon-down:before{
  content: ' ';
  color: rgba(0, 0, 0, 0.54);
  box-sizing: border-box;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid;
  border-bottom: 0px solid;
  margin: 0 4px;
  top: 20px;
  position: absolute;
}
.sort-asc.datatable-icon-up:after, .sort-asc.datatable-icon-up:before{
  content: ' ';
  color: rgba(0, 0, 0, 0.54);
  box-sizing: border-box;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid;
  border-top: 0px solid;
  margin: 0 4px;
  top: 20px;
  position: absolute;
}
/* MATERIJALNI TROSKOVI, izmena na selektovanim redovima KRAJ*/

/* [A-2013] (https://enelit.msce.rs/activity_detail?id=f0d051d2c13ffdbf93d667a7)
prevlacenjem eventa na kalendaru, oni koji se prevlace nisu vidiljivi od isplaniranih */
full-calendar .fc-event-container.fc-mirror-container{
  z-index: 9999!important;
}

/* CSS ZA ACTIVITY-TEAM-CHANGE.DIALOG [A-1858]*/
@media (max-height:500px) and (min-width: 640px){
  activity-team-change form{
    display: flex!important;
    flex-direction: row!important;
  }
  activity-team-change mat-dialog-actions{
    padding-left: 50px!important;
  }
}
/* CSS ZA ACTIVITY-TEAM-CHANGE.DIALOG KRAJ */
/* CSS ZA PREPISKU */
request-detail post .tc-red-600-transparent,
activity-detail post .tc-red-600-transparent{
  color: rgba(229,57,53,0.2)!important;
}
request-detail post .tc-red-600-transparent:hover,
activity-detail post .tc-red-600-transparent:hover{
  color: rgb(229,57,53)!important;
}
request-detail post .tc-blue-600-transparent, 
activity-detail post .tc-blue-600-transparent{
  color: rgba(30,136,229,0.2)!important;
}
request-detail post .tc-blue-600-transparent:hover, 
activity-detail post .tc-blue-600-transparent:hover{
  color: rgb(30,136,229)!important;
}
request-detail post .post-header, 
activity-detail post .post-header{
  background-color: unset !important;
}
request-detail .post, 
activity-detail .post{
  border-radius: 0px !important;
  border: 1px solid #ccc;
}

request-detail post .post-message .ql-container.ql-snow, 
activity-detail post .post-message .ql-container.ql-snow{
  border: unset !important;
}
request-detail post .post-message .ql-editor, 
activity-detail post .post-message .ql-editor{
  padding-top: 0!important;
  padding-left: 0!important;
  margin-top: 0!important;
  margin-left: 0!important;
}

/* Editor */
request-detail .post post-submit mat-card-content, 
activity-detail .post post-submit mat-card-content{
  background-color: #F0F0F0!important;
  padding: 0px!important;
}
request-detail .post post-submit mat-card, 
activity-detail .post post-submit mat-card{
  box-shadow: none!important;
}
request-detail .post post-submit td-file-input button, 
activity-detail .post post-submit td-file-input button{
  background-color:rgba(0, 0, 0, 0.12)!important;
}
request-detail .post post-submit mat-card .ql-toolbar.ql-snow, 
activity-detail .post post-submit mat-card .ql-toolbar.ql-snow{
  border:0;
  padding-left: 0px;
}
request-detail .post post-submit mat-card .ql-toolbar.ql-snow .ql-picker-label,
activity-detail .post post-submit mat-card .ql-toolbar.ql-snow .ql-picker-label{
  padding-left: 0px;
}
request-detail .post post-submit mat-card .ql-snow .ql-picker.ql-font, 
activity-detail .post post-submit mat-card .ql-snow .ql-picker.ql-font{
  width: 100px;
}
/* editor */
request-detail .post post-submit mat-card .ql-container.ql-snow, 
activity-detail .post post-submit mat-card .ql-container.ql-snow{
  background-color:white!important;
  border-radius: 3px;
  margin-bottom: 5px;
}
.post-reply:hover{
  cursor: pointer;
  text-decoration: underline;
}
/* POST ZA PREPISKU KRAJ */


/* ASSET KALENDAR TIMELINE POCETAK */

.fc-timeline-event.fc-not-start:before, 
.fc-timeline-event.fc-not-end:after{
  display: none!important;
}
/* fullscreen kalendara- Iskljucujemo prikaz svih nepotrebnih elemenata*/
body.calendarFullscreenMode asset-search-and-view>div>span:first-child,
body.calendarFullscreenMode mat-sidenav, 
body.calendarFullscreenMode mat-toolbar{
  display:none;
}
body.calendarFullscreenMode mat-sidenav-content.mat-drawer-content { 
  display: inline!important;
  margin-left: 0px!important;
}
body.calendarFullscreenMode mat-sidenav-content.mat-drawer-content>div>div>div { 
 overflow: hidden!important; /* ukidamo scrol na seearch and list */
}
/* fullscreen kalendara kraj */

/* tooltip pocetak Preuzeto sa W3, malo prolagodjen*/

.tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 10px 5px;
  position: absolute;
  z-index: 1000!important;
  left: -7px;
  opacity: 0;
  transition: opacity 0.3s;
  bottom: 20px;
  white-space: pre-line;
  text-align: left;
}
.tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 10%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}
full-calendar .fc-event-container i.fa {
  position: relative;
}
full-calendar .fc-event-container i.fa:hover {
  color:grey
}
full-calendar .fc-event-container i.fa:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
/* tooltip kraj */
/* naslov kalendara, datum string */
full-calendar div.fc-toolbar.fc-header-toolbar div.fc-center h2 {
  font-size: 15px;
}
/* /////////// */

full-calendar tr[data-resource-id] span.fc-cell-text{
  cursor: pointer;
}
full-calendar tr[data-resource-id] span.fc-cell-text:hover{
  color: #ff5722!important;
}
full-calendar tr[data-resource-id] a.fc-timeline-event{
  cursor: pointer;
}


/* Popravka novog kalendara jer ga stari kolje */
full-calendar .fc-view.fc-resourceTimelineYear-view .fc-event,
full-calendar .fc-view.fc-resourceTimelineMonth-view .fc-event,
full-calendar .fc-view.fc-resourceTimelineWeek-view .fc-event,
full-calendar .fc-view.fc-resourceTimelineDay-view .fc-event{
  position: absolute!important;
  margin: 1px 2px 0!important;
}
full-calendar .fc-button .fc-icon {
  top: 0.05em!important;
}

/* popravka starog kaledara jer ga novi kolje */
ng-fullcalendar div.fc-toolbar.fc-header-toolbar{
  display:block;
}
ng-fullcalendar .fc-icon-right-single-arrow:after,
ng-fullcalendar .fc-icon-left-single-arrow:after{
  font-size: 150%;
  top: -25%;
}
/* ASSET KALENDAR TIMELINE KRAJ */

/* PROFILE PHOTO POCETAK */
.profile-foto-holder>div:hover .profile-photo-cover{
  height: 40%!important;
 }
 .profile-photo-cover:hover{
   opacity: 0.9!important;
 }
/* PROFILE PHOTO  KRAJ */
/* NOTES POCETAK*/
/* body.note-mode ng-fullcalendar table thead th.fc-day-header[data-date]{
  background-color:#ff5722;
  color: white; 
  cursor: pointer;
}
body.note-mode ng-fullcalendar table thead th.fc-day-header.header-has-note[data-date]{ */
  /* background-color:red; */
/*   position: relative;
}
body.note-mode ng-fullcalendar table thead th.fc-day-header.header-has-note[data-date] i.fa{
  display: inline-block!important;
  position: absolute;
  top: 2px;
  right: 5px;
} */
/* NOTES KRAJ */

/* CSS declarations go here */
.bold {
  font-weight: bold;
}
.upperCase {
  text-transform: uppercase;
}
.boldUpperCase {
  text-transform: uppercase;
  font-weight: bold;
}

/* Labele na autocomplete chipovima su vise nego na drugim search fieldovima. i.e. asset i vlasnik na formi zahteva */
td-chips label.mat-empty.mat-form-field-empty.mat-accent.ng-star-inserted { top: 22px!important; }

/* DRAG DROP DIALOG */
.drag-drop-list {
  width: 500px;
  max-width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}
.drag-drop-box {
  margin: 4px 0!important;
  padding: 15px!important;
  border-bottom: solid 1px #ccc!important;
  color: rgba(0, 0, 0, 0.87)!important;
  display: flex !important;
  flex-direction: row!important;
  align-items: center!important;
  justify-content: space-between!important;
  box-sizing: border-box!important;
  cursor: move!important;
  font-size: 14px!important;
}
.cdk-drag-preview {
  box-sizing: border-box!important;
  border-radius: 4px!important;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.3),
              0 8px 10px 1px rgba(0, 0, 0, 0.21),
              0 3px 14px 2px rgba(0, 0, 0, 0.18)!important;
}
.cdk-drag-placeholder {
  opacity: 0!important;
}
.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1)!important;
}
.drag-drop-list.cdk-drop-list-dragging .drag-drop-box:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1)!important;
}
body{
  overflow: hidden!important; /* Palio se scrol */
}
/* KRAJ DRAG DROP DIALOG */

/* Prvi chip nije bio poravnat sa početkom */
watcher-single .mat-chip-list-wrapper mat-chip:first-child{
  margin-left: 0px!important;
}

/* skinuta je margina da bi komponente bile poravnate */
send-email-dialog .mat-chip-list-wrapper{
  margin-left: 0px!important;
  margin-right:8px!important
}

/* dugme za odlazak na vrh stranice A-1638 */
dashboard .go-to-top-button,
request-detail .go-to-top-button{
  display: none;
}
.go-to-top-button{
  order: 1;flex: 0 1 auto;
  align-self: auto; 

  margin: 0px!important;
  padding: 0px!important;
  min-width: 0px!important;

  width: 0px!important;
  transition: 500ms ease-in-out 0.05s;
}
.scroll-to-top-isvisible-btn .go-to-top-button{
  margin: 0 8px 0 0!important;
  width: 46px!important;
} 
.go-to-top-button mat-icon{
  transform: scale(1.5);
  font-size: 0px;
  transition: 500ms ease-in-out 0.05s;
}
.scroll-to-top-isvisible-btn .go-to-top-button mat-icon{
  font-size: 24px; 
}

.button-holder{
  margin: 10px 0px;
}

.button-clear-primary{
  background-color: rgb(213, 234, 252)!important; 
  color: rgb(2,119,189)!important;
}

.button-clear-accent{
  background-color: rgb(248, 204, 191)!important; 
  color: rgb(255,87,34)!important;
}

/* stilizovanje expansion header na administraciji profila A-1605*/ 
profile_administration .td-expansion-panel-header mat-icon{
  color: black!important; 
}
profile_administration .td-expansion-panel-header .td-expansion-label{
  font-size: 14px;
}
profile_administration .td-expansion-panel-header {
  background-color: whitesmoke;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

@media (max-width:959px){
  role-card .mat-checkbox, role-card .cdk-virtual-scroll-content-wrapper{
    font-size: 12px;
  }
  .search-and-view-scroll {
    height: auto;
  }
}

@media (max-width:500px){
  td-paging-bar mat-select.mat-select>div>div{
    text-align: end;
    width: unset;
    max-width: unset;
    padding-right: 2px;
   } 
  td-paging-bar>div>span{
    margin-top: 5px!important;
    text-align: center;
    min-width: 47px;
  }
  td-paging-bar mat-select.mat-select{
     margin: 3px 0 0 0!important; 
  }
  td-paging-bar .td-paging-bar-navigation{
    min-width: 160px!important;
    margin: 0 0 0 5px!important;
  } 
  td-paging-bar>div{
    justify-content:center!important;
  }
  .search-and-view-scroll {
    height: auto;
  }
}

@media (min-width:960px){
  .search-and-view-scroll {
    height: calc(100vh - 60px);
  }
  /* Ikonice sa kartica se pale na mouseenter kao na gmail-u */
  td-loading mat-card.mat-card span[fxlayout="column"],
  checklist-list mat-card.mat-card span[fxlayout="column"],
  location-list mat-card.mat-card span[fxlayout="column"],
  assets-list mat-card.mat-card span[fxlayout="column wrap"],
  request-types-list mat-card.mat-card span[fxlayout="column wrap"],
  activity-types-list mat-card.mat-card span[fxlayout="column wrap"],
  team-list mat-card.mat-card span[fxlayout="row wrap"],
  domain-list mat-card.mat-card span[fxlayout="row wrap"],
  customers-list mat-card.mat-card span[fxlayout="row wrap"],
  asset-list mat-card.mat-card span[fxlayout="column wrap"]{ 
    visibility: hidden!important;
  }

  td-loading .mat-list-item-focused mat-card.mat-card span[fxlayout="column"],
  checklist-list .mat-list-item-focused mat-card.mat-card span[fxlayout="column"],
  location-list .mat-list-item-focused mat-card.mat-card span[fxlayout="column"],
  assets-list .mat-list-item-focused mat-card.mat-card span[fxlayout="column wrap"],
  request-types-list .mat-list-item-focused mat-card.mat-card span[fxlayout="column wrap"],
  activity-types-list .mat-list-item-focused mat-card.mat-card span[fxlayout="column wrap"],
  team-list .mat-list-item-focused mat-card.mat-card span[fxlayout="row wrap"],
  domain-list .mat-list-item-focused mat-card.mat-card span[fxlayout="row wrap"],
  customers-list .mat-list-item-focused mat-card.mat-card span[fxlayout="row wrap"],
  asset-list .mat-list-item-focused mat-card.mat-card span[fxlayout="column wrap"]{ 
    visibility: visible!important; 
  }
}

/* Fokus na karticama kada je mouseeenter */
.mat-list-item-focused mat-card.mat-card{
  box-shadow: 0px 5px 0.2rem rgba(204,204,204,1), 0px -3px 0.2rem rgba(204,204,204,1)!important;
  /* transition: transform .2s;
  transform: scale(1.001); */
}


.mark-error-fields-class mat-hint, 
.mark-error-fields-class mat-error {
  display: block!important; /* Tek sa dodavanjem klase mark-error-fields-class se otkrivaju */
}
.required-background-class .mat-form-field-flex {
  background-color: rgba(211,211,211,0.3)!important;
}
.required-background-class .mat-form-field-flex label {
  padding-left: 3px!important;
}
/* required fields kraj*/


.notification-item-focused {
  background-color: #1c7baa!important;
  /* transition: transform .2s;
  transform: scale(1.001); */
} 
/* Button-i za pretrage na svim parametrima pretraga*/
.search-button-group {
  position:-webkit-sticky; 
  position:sticky; 
  bottom:5px; 
  z-index: 1000;
}
.sticky-form-button-group {
  position:-webkit-sticky; 
  position:sticky; 
  bottom:5px; 
  z-index: 1000;
}

.small-class {
  height: 10rem;
}

/* td-paging-bar - ovi */
td-paging-bar{
  background-color:white;
  position:-webkit-sticky; 
  position:sticky; bottom:0px; 
  z-index: 999;
  box-shadow: 0 -7px 5px -5px rgba(0,0,0,0.5);
}

/* aktivnosti u kalendaru preko kontrolne table. A-1619*/
.fc-view, .fc-view > table {
  z-index: unset!important;
}
.fc-popover.fc-more-popover{
  z-index:1005!important;
    max-height: 70%;
    overflow-y: auto;
}


/* card-title - ovi */
labels-administration .enelit-card-title,
dashboard .td-expansion-panel-header,
activity-view .enelit-card-title-activities,
request-view .enelit-card-title-requests,
asset-view .enelit-card-title-assets,
customers-view .enelit-card-title-requests,
contracts-view .enelit-card-title-requests,
asset-list .enelit-card-title-requests,
asset-model-list .enelit-card-title-requests,
asset-manufacturer-list .enelit-card-title-requests,
asset-type-list .enelit-card-title-requests,
asset-category-list .enelit-card-title-requests,
users-list .enelit-card-title,
team-list .enelit-card-title,
domain-list .enelit-card-title,
request-types-list .enelit-card-title,
activity-types-list .enelit-card-title,
organization-unit-type-list .enelit-card-title{
  position:-webkit-sticky!important; 
  position:sticky!important; 
  top:1px; 
  z-index: 1002;
}

.shadow-all{
  box-shadow: 0 0 7px 1px rgba(0,0,0,0.5);
}
/* Svi gornji beli ukljucujuci i one sa kontrolne table */
.shadow-bottom,
td-loading>div>div[layout="row"]{
  background-color: white;
  box-shadow: 0 7px 5px -5px rgba(0,0,0,0.5);
}
/* Gornji beli bez kontrolne table ali ne uključuju one unutar stepova */
.top-sticky-29,
mat-card.mat-card>*>td-loading>div>div[layout="row"]{
  position:-webkit-sticky; 
  position:sticky; 
  top:29px; 
  z-index: 5;  
} 
/* Gornji beli sa kontrolne table */
dashboard td-loading>div>div[layout="row"]{
  position:-webkit-sticky; 
  position:sticky; 
  top:45px; 
  z-index: 5;
} 

/* mobilni landscape iskljucujemo sticky koji su iznad ukljuceni*/
@media (max-height:500px){
  /* Svi gornji beli ukljucujuci i one sa kontrolne table */
  .shadow-bottom,
  td-loading>div>div[layout="row"]{ 
    position:static!important; 
  }
  /* card-title - ovi */
  labels-administration .enelit-card-title,
  dashboard .td-expansion-panel-header,
  activity-view .enelit-card-title-activities,
  request-view .enelit-card-title-requests,
  customers-view .enelit-card-title-requests,
  contracts-view .enelit-card-title-requests,
  asset-list .enelit-card-title-requests,
  asset-model-list .enelit-card-title-requests,
  asset-manufacturer-list .enelit-card-title-requests,
  asset-category-list .enelit-card-title-requests,
  users-list .enelit-card-title,
  team-list .enelit-card-title,
  domain-list .enelit-card-title,
  request-types-list .enelit-card-title,
  activity-types-list .enelit-card-title,
  organization-unit-type-list .enelit-card-title{
    position:static!important; 
  }
}

/* Ikonice: sortiraj, preuzmi, stampaj*/
asset-list mat-button-toggle-group, 
asset-list mat-button-toggle{
  border: none!important;
}
asset-list .mat-button-toggle-checked{
  background-color: white!important;
  color: #0277bd!important;
}

.mat-button-toggle-label-content {
  line-height: 36px!important;
}
mat-slide-toggle.mat-slide-toggle{
  vertical-align: middle;
}

/* korisceno nekad kao <button mat-button [ngClass.xs]="{'small-button': true}" na activity-list, mozda opet zatreba */
/* .small-button {
  padding: 0px!important;
  min-width: 44px!important;
} */
/* ikonice KRAJ */

location-mat-autocomplete-chips mat-chip.mat-standard-chip{
  height: unset!important;
  width: unset!important;
  padding-top: 3px!important;
  padding-bottom: 3px!important;
}

.quick-search >.mat-option.mat-active{
  filter: brightness(96%);
  font-weight: bold!important;
  font-size: 13.4px!important; 
  transition: font-size 0.4s!important;
}
.quick-search.mat-form-field-appearance-outline .mat-form-field-flex > .mat-form-field-infix { 
  padding: 9px 0px !important;
}
.quick-search.mat-form-field-appearance-outline .mat-form-field-label-wrapper { 
  top: -17px!important;  
}
.quick-search.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
  transform: translateY(-1.1em) scale(.75)!important;
}
.quick-search.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
color: white!important;
}
.quick-search.mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: white!important;
}
.quick-search.mat-form-field-appearance-outline .mat-form-field-outline {
  background-color: #0070b3!important;
  border-radius: 5px;
}
.quick-search.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline {
  background-color: #00639e!important;
  border-radius: 5px;
}

ng-fullcalendar table thead th.fc-day-header {
  padding: 10px 0px 10px 0px !important;
  background-color: darkgrey;
}
teams-mat-autocomplete-chips input.mat-chip-input {
  margin: 0px !important;
} 

.example-spacer {
  flex: 1 1 auto;
}

html {
  font-family: Roboto, Helvetica Neue, sans-serif;
}

.tc-grey-700 {
  color: #616161!important;
}

.mat-table-container {
  display: block;
  max-width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

/*.alert{
  padding:15px;
  margin-bottom:20px;
  border:1px solid transparent;
  border-radius:4px
}

.alert-danger{
  color:#a94442;
  background-color:#f2dede;
  border-color:#ebccd1
}*/

.tricky-select {
  width: 100%;
}

.tricky-select .mat-input-underline {
  display: none;
}

.post {
  border: 1px solid darkgray;
  background-color: #F0F0F0;
  margin-bottom: 8px;
}

.post-header {
  font-size: 12px;
  color: #4B4B4B;
  background-color: #D8D8D8;
  padding-left: 4px;
  padding-right: 4px;
  padding-top: 4px;
}

.post-message {
  font-size: 13px;
  color: #2B2B2B;
  margin: 4px;
}


.post-attachments {
  font-size: 12px;
  margin-left: 4px;
  margin-right: 4px;
  padding-bottom: 4px;
  background-color: #F1F1F1;
  color: grey;
  text-decoration: none;
}

.mat-small {
  height: 20px;
  line-height: 20px;
  min-height: 20px;
  vertical-align: top;
  font-size: 10px;
  padding: 0 0;
  margin: 0;
  width: 20px;
}

/* Pocetak css-a za context-menu */

context-menu-content .show {
  display: block!important;
}
context-menu-content .dropdown, .dropup {
  position: relative;
}

context-menu-content .dropdown-menu {
position: absolute;
top: 100%;
left: 0;
z-index: 1000;
display: none;
float: left;
min-width: 160px;
padding: 5px 0;
margin: 2px 0 0;
font-size: 14px;
text-align: left;
list-style: none;
background-color: #fff;
-webkit-background-clip: padding-box;
background-clip: padding-box;
border: 1px solid #ccc;
border: 1px solid rgba(0, 0, 0, .15);
border-radius: 4px;
-webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
box-shadow: 0 6px 12px rgba(0, 0, 0, .175)
}

context-menu-content .dropdown-menu.pull-right {
right: 0;
left: auto
}

context-menu-content .dropdown-menu .divider {
height: 1px;
margin: 9px 0;
overflow: hidden;
background-color: #e5e5e5
}

context-menu-content .dropdown-menu>li>a {
display: block;
padding: 3px 20px;
clear: both;
font-weight: 400;
line-height: 1.42857143;
text-decoration: none!important;
color: #333;
white-space: nowrap
}

/* Kraj css-a za context-menu */

.card-field-desc {
  margin-top: 8px;
  color: gray;
  font-size: 12px;
}

.card-field-missed-deadline {
  color: red;
  font-weight: bold;
  text-align: left;
}

.card-field-missed-deadline-exclamation {
  color: red;
  font-weight: bold;
  font-size: 24px;
}

.mat-radio-label-content {
  white-space: normal;
  word-break: break-all;
}


/* fallback */

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: local('Material Icons'), local('MaterialIcons-Regular'), url(https://fonts.gstatic.com/s/materialicons/v22/2fcrYFNaTjcS6g4U3t-Y5ZjZjT5FdEJ140U2DJYC3mY.woff2) format('woff2');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

.watcher {
  border: 1px solid cadetblue;
  border-color: cadetblue;
  background-color: aliceblue;
  margin-right: 5px;
  border-radius: 3px;
  text-align: center;
}

.watcher_group {
  border: 1px solid lightgray;
  border-color: lightgray;
  background-color: ghostwhite;
  border-radius: 2px;
  margin-bottom: 5px;
  padding: 5px;
}

.mail {
  border: 1px solid cadetblue;
  border-color: cadetblue;
  background-color: aliceblue;
  border-radius: 5px;
  margin-right: 5px;
  padding: 1px;
  line-height: 40px;
  vertical-align: middle;
}

.enelit-card-title {
  background-color: whitesmoke;
  font-family: Roboto, Helvetica Neue, sans-serif;
  padding-top: 2px;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 2px;
  font-size: 13px;
  color: #333333;
  display: flex;
  align-items: center;
  vertical-align: middle;
}

.enelit-card-title-activities {
  background-color: #ff5722;
  font-family: Roboto, Helvetica Neue, sans-serif;
  padding-top: 2px;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 2px;
  font-size: 13px;
  color: #ffffff;
  display: flex;
  align-items: center;
  vertical-align: middle;
}

.enelit-card-title-invoices {
  background-color: #8154AC;
  font-family: Roboto, Helvetica Neue, sans-serif;
  padding-top: 2px;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 2px;
  font-size: 13px;
  color: #ffffff;
  display: flex;
  align-items: center;
  vertical-align: middle;
}

.enelit-card-title-requests {
  background-color: #0277bd;
  font-family: Roboto, Helvetica Neue, sans-serif;
  padding-top: 2px;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 2px;
  font-size: 13px;
  color: #ffffff;
  display: flex;
  align-items: center;
  vertical-align: middle;
}

.enelit-card-title-quick-menu {
  background-color: #6E6E6E;
  font-family: Roboto, Helvetica Neue, sans-serif;
  padding-top: 2px;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 2px;
  font-size: 13px;
  color: #ffffff;
  display: flex;
  align-items: center;
  vertical-align: middle;
}

.enelit-card-title-quick-menu-bg {
  background-color: #e0e0e0;
}

.enelit-card-title-customers {
  background-color: MediumSeaGreen;
  font-family: Roboto, Helvetica Neue, sans-serif;
  padding-top: 2px;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 2px;
  font-size: 13px;
  color: #ffffff;
  display: flex;
  align-items: center;
  vertical-align: middle;
}

.enelit-card-title-assets {
  background-color: lightcoral;
  font-family: Roboto, Helvetica Neue, sans-serif;
  padding-top: 2px;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 2px;
  font-size: 13px;
  color: #ffffff;
  display: flex;
  align-items: center;
  vertical-align: middle;
}

.enelit-card-title-users {
  background-color: dimgray;
  font-family: Roboto, Helvetica Neue, sans-serif;
  padding-top: 2px;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 2px;
  font-size: 13px;
  color: #ffffff;
  display: flex;
  align-items: center;
  vertical-align: middle;
}

.enelit-card-title-contracts {
  background-color: gold;
  font-family: Roboto, Helvetica Neue, sans-serif;
  padding-top: 2px;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 2px;
  font-size: 13px;
  color: #ffffff;
  display: flex;
  align-items: center;
  vertical-align: middle;
}

.enelit-card-title-checklists {
  background-color: #999999;
  font-family: Roboto, Helvetica Neue, sans-serif;
  padding-top: 2px;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 2px;
  font-size: 13px;
  color: #ffffff;
  display: flex;
  align-items: center;
  vertical-align: middle;
}

.enelit-card-title-requests-buton {
  font-family: Roboto, Helvetica Neue, sans-serif;
  font-size: 13px;
  color: #ffffff;
}

.enelit-card-content-label {
  font-size: 13px;
}

.enelit-card-content {
  font-size: 13px !important;
  font-weight: bold !important;
}


.td-circle.mat-active {
  background-color: #0277bd !important;
}

.mat-active-activities {
  background-color: #ff5722 !important;
}

.mscfonts {
  font-family: 'Roboto', sans-serif; 
  font-size: 13px;
}

.mat-drawer-inner-container::-webkit-scrollbar {
  display: none;
}

.asset-documents {
  font-size: 13px;
  margin-left: 2px;
  margin-right: 2px;
}

/* fix da se ne prikazuje spinner na input text component koji su tipa number */
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

input.mat-input-element{
  font-size: 13px !important;
  line-height: 1em !important;
}

mat-select .mat-select-trigger {
  font-size: 13px !important;
}

.mat-form-field-placeholder-wrapper {
  font-size: 13px !important;
}

.mat-form-field {
  font-size: 13px !important;
}

/* na čeklistama i prilikom njihove stampe mat form field se menja jer je padding zezao format */
/* checklist-edit .mat-form-field-appearance-legacy .mat-form-field-infix{
  padding: 0px !important;
}

checklist-edit .mat-form-field-infix {
  padding: 0px !important;
  border-top: none !important;
}
@media print{
  .mat-form-field-appearance-legacy .mat-form-field-infix{
  padding: 0px !important;
}

.mat-form-field-infix {
  padding: 0px !important;
  border-top: none !important;
}
} */

.notificationFilter .mat-select-value {
  color: rgba(255, 255, 255, 0.972) !important; }

.notificationFilter .mat-select-arrow {
  color: rgba(255, 255, 255, 0.972) !important; }

.notificationFilter .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(255, 255, 255, 0.972); }
.mat-checkbox{
  font-size: 13px;
}

@media screen {
  .noPrint{}
  .noScreen{display:none;}
}

@media print {
  .noPrint{display:none;}
  .noScreen{}
}

.div-centered {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.search-params {
  border-bottom: 1px solid gray;
  height: 20px;
  font-size: 11px;
}

.search-params-desc {
  color: gray;
  font-size: 10px;
}

.mat-option {
  font-size: 13px important!;
}

/* tree style */
textarea.mat-input-element {
  font-size: 13px !important;
  /* line-height: 1.35 !important; */
}

.mat-toolbar-single-row {
  height: 54px !important;
}

/* A-432: Prikaz kalendara - preko izlistanih aktivnosti se vidi dugme NEDELJNO */
.fc-toolbar .fc-state-active,
.fc-toolbar .ui-state-active {
	z-index: 1 !important;
}

/* Fix za td-steps u covalent 2.0.0-beta.3 sto ne radi u IE11 
 * https://github.com/Teradata/covalent/issues/1153
 */
div.td-navigation-drawer-menu-content.ng-trigger.ng-trigger-tdCollapse{
  display: block !important;
  animation: gen_css_kf_101 0ms linear 0ms 1 normal forwards running;
}
div.td-navigation-drawer-content.ng-trigger.ng-trigger-tdCollapse.ng-animating{
  display: block !important;
  height: auto !important;
  animation: gen_css_kf_101 0ms linear 0ms 1 normal forwards running;
}
div.td-step-content-wrapper.ng-trigger.ng-trigger-tdCollapse{
  animation: gen_css_kf_6 0ms linear 0ms 0 normal forwards running;
  height: auto;
  display: block !important;
  overflow: auto;
}

.td-step-label {
  font-size: 12px !important;
  color: #666666 !important;
}

.td-step-sublabel {
  font-size: 11px !important;
  font-weight: bold;
  color: #333333 !important;
}

.snotifyToast__body{
  font-size: 0.85em !important;
}

.snotifyToast__title{
  font-size: 1.3em !important;
}

.mat-toolbar-row {
  height: 40px !important;
  font: 400 14px/16px Roboto, sans-serif;
}

.mat-toolbar-multiple-rows {
  min-height: 40px !important;
}

.virtual-scroll-viewport {
  height: 400px;
  width: 100%;
}

.virtual-scroll-item {
  min-height: 50px;
  border: 0.1px thin whitesmoke;
  display: flex;
  border-bottom: 1px solid whitesmoke;
}
.virtual-scroll-item>.mat-checkbox, .virtual-scroll-item>div{
  align-self: center;
}

.mat-dialog-title {
  font: 400 14px/20px Roboto, sans-serif !important;
  font-weight: bold !important;
 }

 .send-email-dialog-content {
  max-height: 70vh;
  overflow-y: auto;
  max-width: 1200px;
}

@media (min-width: 960px) {
  .send-email-dialog-content {
    width: 1200px;
  }
}

@media (max-width: 959px) {
  .send-email-dialog-content {
    width: 98%;
  }
}


 .accentSelector .mat-select-value {
  color: #ef6c00 !important; }

.accentSelector .mat-select-arrow {
  color: #ef6c00 !important; }

.accentSelector .mat-form-field-underline {
  background-color: #ef6c00 !important; }

.primarySelector .mat-select-value {
    color: #1976d2 !important; }
  
.primarySelector .mat-select-arrow {
    color: #1976d2 !important; }
  
.primarySelector .mat-form-field-underline {
    background-color:#1976d2 !important; }

 /* za dodavanje fonta u quill editor*/
.ql-editor .ql-font-roboto {
  font-family: 'Roboto';
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value=roboto]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value=roboto]::before {
  content: 'Roboto';
}

.ql-snow .ql-picker.ql-font .ql-picker-item[data-value=roboto]::before {
  font-family: 'Roboto';
}

.pulse {
animation: animate 3s linear infinite;
}
@keyframes animate{
0%{
box-shadow: 0 0 0 0 rgba(255,109,74, 0.7), 0 0 0 0 rgba(255,109,74,0.7); 
}
40%{
  box-shadow: 0 0 0 15px rgba(255,109,74,0.7), 0 0 0 0 rgba(255,109,74,0.7); 

}
80%{
  box-shadow: 0 0 0 15px rgba(255,109,74,0), 0 0 0 9px rgba(255,109,74,0); 

}
100%{
  box-shadow: 0 0 0 0 rgba(255,109,74,0), 0 0 0 9px rgba(255,109,74,0); 

}
}

.mat-menu-panel.unlimitedWidth { max-width: none !important; }

td-dialog-actions{
  display: flex;
  flex-direction: row-reverse!important;
 
}

location-advanced .mat-list-item-content {
  padding: 0!important;
}

@keyframes border-pulsate {
  0%   { border-color: red; }
  40%  { border-color: white; }
  70% { border-color: red; }
  100%  { border-color: white; }

}

.ariaRequired {
  border: 2px solid white;
  border-radius:5px;
  animation: border-pulsate 3s;
}
.ariaRequiredWithBackground {
  border: 2px solid red;
  border-radius:5px;
  background-color:rgba(211,211,211,0.3)!important;
  animation: border-pulsate infinite;
}
.matCardTopFlat {
  border-start-start-radius: 0px!important;
  margin-top: 0px!important;
  border-start-end-radius: 0px!important;
}
.white-shadow-circle-12 {
  background-color:#fff;
  width:12px;
  height:12px;
  border-radius:50%;
  box-shadow: 0 0 3px 1px #6b6b6b;
  -moz-border-radius:50%;
  -webkit-border-radius:50%;
}
.scrollbar-hidden {
  -ms-overflow-style: none;
  scrollbar-width: none; /* Firefox, IE, Edge */
}
.scrollbar-hidden::-webkit-scrollbar {
  display: none;  /* Chrome, Opera, Safari */
}
.search-and-view-scroll {
  overflow-y: auto; 
  overflow-x: hidden;
}
  /* Firefox */
.scroller {
  scrollbar-width: auto;
  scrollbar-color: #828282 transparent;
}
/* Chrome, Edge, and Safari */
.scroller::-webkit-scrollbar {
  width: 10px;
 }

.scroller::-webkit-scrollbar-track {
  background: transparent;
}

.scroller::-webkit-scrollbar-thumb {
  background-color: #969696;
  border-radius: 10px;
  border:3px solid transparent;
 }

 .dialog-title {
   margin-top: 0px;
   margin-bottom: 16px;
 }

 .drag-and-drop-enter {
   border: none;
   background-color: #1C6EA4;
   transition: 0.5s;
   color:white;
 }
 
 .drag-and-drop-enter mat-icon {
   color: white !important;
 }

 .drag-and-drop-enter button {
   border: 1px solid white !important;
 }

.multiline-tooltip{
  white-space: pre-line;
} 

.copy-url-icon mat-icon {
  visibility: hidden;
}

.copy-url-icon:hover mat-icon {
  visibility: visible;
  cursor: pointer;
}

.copy-url-icon:before {
    content:"Copied";
    position:absolute;
    top:30px;
    right:0px;
    background:#424242;
    padding:6px 10px;
    border-radius:20px;
    font-size:15px;
    display: none;
    z-index: 1000;
    font-size: 12px;
  }
  .copy-url-icon:after {
    content:"";
    position:absolute;
    top:25px;
    right:25px;
    width:10px;
    height:10px;
    background:#424242;
    transform:rotate(45deg);
    display: none;
    z-index: 1000;
  }
  .copy-url-icon.active:before,
  .copy-url-icon.active:after {
    display: block !important;
  }